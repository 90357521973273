import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Navbar() {
  const { isAuthenticated, logOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const token = sessionStorage.getItem("token");
      const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      };
      let response = await fetch(`/api/logout`, requestOptions);
      if (response.status === 200) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("currentAdminId");
        logOut();
        navigate('/');
      } else {
        navigate('/');
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className="navbar">
      <div className="container">
        <ul className="navbar-menu" style={{ listStyleType: 'none' }}>
          <li>
            <Link
              to="/admin_list"
              style={location.pathname === '/admin_list' || location.pathname === '/' ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {}}
            >
              Admin List
            </Link>
          </li>
	  <li>
	    <Link
	      to="/user_list"
	      style={location.pathname === '/user_list' ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {}}
	    >
	      User List
	    </Link>
	  </li>
          <li>
            <Link
              to="/configuration/edit"
              style={location.pathname === '/configuration/edit' ? { backgroundColor: 'rgba(0,0,0,0.1)' } : {}}
            >
              Configuration
            </Link>
          </li>
          <li>
            <a href="/" style={{ cursor: 'pointer' }} onClick={(e) => handleLogout(e)}>Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
