import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import M from 'materialize-css';

function ConfigurationEdit() {
    const { isAuthenticated } = useAuth();
    const [message, setMessage] = useState("");
    const [successMessage, setSuccsessMessage] = useState("");
    const [envState, setEnvState] = useState("prod");

    const [walkingInitialTimeoutSeconds, setWalkingInitialTimeoutSeconds] = useState(0);
    const [walkingPointsMinDistInterval, setWalkingPointsMinDistInterval] = useState(0);
    const [walkingFrequencyThreshold, setWalkingFrequencyThreshold] = useState(0);
    const [walkingCountGraceMultiplier, setWalkingCountGraceMultiplier] = useState(0);

    const [drivingInitialTimeoutSeconds, setDrivingInitialTimeoutSeconds] = useState(0);
    const [drivingPointsMinDistInterval, setDrivingPointsMinDistInterval] = useState(0);
    const [drivingFrequencyThreshold, setDrivingFrequencyThreshold] = useState(0);
    const [drivingCountGraceMultiplier, setDrivingCountGraceMultiplier] = useState(0);

    const [transitInitialTimeoutSeconds, setTransitInitialTimeoutSeconds] = useState(0);
    const [transitPointsMinDistInterval, setTransitPointsMinDistInterval] = useState(0);
    const [transitFrequencyThreshold, setTransitFrequencyThreshold] = useState(0);
    const [transitCountGraceMultiplier, setTransitCountGraceMultiplier] = useState(0);

    const navigate = useNavigate();

    const handleDev = () => {
      setEnvState("dev");
    };

    const handleProd = () => {
      setEnvState("prod");
    };

    const fetchConfig = useCallback(async () => {
        try {
            const token = sessionStorage.getItem("token");
            if (token === null) {
                navigate("/login");
                return;
            }
            const requestOptions = {
                method: 'GET',
                headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY, 'X-Env': 'prod' },
            };
            const response = await fetch(`/api/configuration`, requestOptions);
            const data = await response.json();
            if (response.status === 200) {
                setWalkingInitialTimeoutSeconds(data.walking.initial_timeout_seconds);
                setWalkingPointsMinDistInterval(data.walking.points_min_dist_interval);
                setWalkingFrequencyThreshold(data.walking.frequency_threshold);
                setWalkingCountGraceMultiplier(data.walking.count_grace_multiplier);

                setDrivingInitialTimeoutSeconds(data.driving.initial_timeout_seconds);
                setDrivingPointsMinDistInterval(data.driving.points_min_dist_interval);
                setDrivingFrequencyThreshold(data.driving.frequency_threshold);
                setDrivingCountGraceMultiplier(data.driving.count_grace_multiplier);

                setTransitInitialTimeoutSeconds(data.transit.initial_timeout_seconds);
                setTransitPointsMinDistInterval(data.transit.points_min_dist_interval);
                setTransitFrequencyThreshold(data.transit.frequency_threshold);
                setTransitCountGraceMultiplier(data.transit.count_grace_multiplier);

            } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("currentAdminId");
                navigate("/login");
            }
        } catch (err) {
            console.error("Error fetching configuration:", err);
        }
    }, [navigate, envState]);

    let handleSubmit = async (e) => {
        e.preventDefault();
        setSuccsessMessage("");
        try {
            const token = sessionStorage.getItem("token");
            if (token === null) {
                navigate("/login");
                return;
            }
            const requestOptions = {
                method: 'PUT',
                headers: { 'Authorization': token, 'Content-Type': 'application/json', 'Env': envState || "prod" },
                body: JSON.stringify({
                    walking: {
                        initial_timeout_seconds: Number(walkingInitialTimeoutSeconds),
                        points_min_dist_interval: Number(walkingPointsMinDistInterval),
                        frequency_threshold: Number(walkingFrequencyThreshold),
                        count_grace_multiplier: Number(walkingCountGraceMultiplier)
                    },
                    driving: {
                        initial_timeout_seconds: Number(drivingInitialTimeoutSeconds),
                        points_min_dist_interval: Number(drivingPointsMinDistInterval),
                        frequency_threshold: Number(drivingFrequencyThreshold),
                        count_grace_multiplier: Number(drivingCountGraceMultiplier)
                    },
                    transit: {
                        initial_timeout_seconds: Number(transitInitialTimeoutSeconds),
                        points_min_dist_interval: Number(transitPointsMinDistInterval),
                        frequency_threshold: Number(transitFrequencyThreshold),
                        count_grace_multiplier: Number(transitCountGraceMultiplier)
                    }
                })
            };
            let response = await fetch(`/api/${envState}/configuration/edit`, requestOptions);
            if (response.status === 200) {
                let resJson = await response.json();
                setSuccsessMessage(resJson.message);
            } else {
                setMessage("Some error occurred");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    useEffect(() => {
        if (dropdownRef.current) {
            const initTimeout = setTimeout(() => {
                var elems = document.querySelectorAll('.dropdown-trigger');
                M.Dropdown.init(elems);
            }, 0);
    
            return () => {
                clearTimeout(initTimeout);
                if (dropdownRef.current) {
                    const instance = M.Dropdown.getInstance(dropdownRef.current);
                    if (instance) {
                        instance.destroy();
                    }
                }
            };
        }
    }, [dropdownRef]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [navigate, isAuthenticated]);

    return (
      <div className="container">
          <h3 className="center-align">Edit Configuration</h3>
          {message && <p className="red-text">{message}</p>}
          {successMessage && <p className="green-text">{successMessage}</p>}
          <form onSubmit={handleSubmit}>
            <table>
                <tr>
                    <th>Walking</th>
                    <th>Driving</th>
                    <th>Transit</th>
                </tr>
                <tr>
                    <td>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="walkingInitialTimeoutSeconds"
                                name="walkingInitialTimeoutSeconds"
                                placeholder=""
                                onChange={(e) => setWalkingInitialTimeoutSeconds(e.target.value)}
                                value={walkingInitialTimeoutSeconds}
                            />
                            <label htmlFor="walkingInitialTimeoutSeconds" className={walkingInitialTimeoutSeconds ? 'active' : ''}>Initial Timeout Seconds</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="walkingPointsMinDistInterval"
                                name="walkingPointsMinDistInterval" 
                                placeholder=""
                                onChange={(e) => setWalkingPointsMinDistInterval(e.target.value)}
                                value={walkingPointsMinDistInterval}
                            />
                            <label htmlFor="walkingPointsMinDistInterval" className={walkingPointsMinDistInterval ? 'active' : ''}>Points MinDist Interval</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="walkingFrequencyThreshold"
                                name="walkingFrequencyThreshold" 
                                placeholder=""
                                onChange={(e) => setWalkingFrequencyThreshold(e.target.value)}
                                value={walkingFrequencyThreshold}
                            />
                            <label htmlFor="walkingFrequencyThreshold" className={walkingFrequencyThreshold ? 'active' : ''}>Frequency Threshold</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="walkingCountGraceMultiplier"
                                name="walkingCountGraceMultiplier" 
                                placeholder=""
                                onChange={(e) => setWalkingCountGraceMultiplier(e.target.value)}
                                value={walkingCountGraceMultiplier}
                            />
                            <label htmlFor="walkingCountGraceMultiplier" className={walkingCountGraceMultiplier ? 'active' : ''}>Count Grace Multiplier</label>
                        </div>
                    </td>
                    <td>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="drivingInitialTimeoutSeconds"
                                name="drivingInitialTimeoutSeconds"
                                placeholder=""
                                onChange={(e) => setDrivingInitialTimeoutSeconds(e.target.value)}
                                value={drivingInitialTimeoutSeconds}
                            />
                            <label htmlFor="drivingInitialTimeoutSeconds" className={drivingInitialTimeoutSeconds ? 'active' : ''}>Initial Timeout Seconds</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="drivingPointsMinDistInterval"
                                name="drivingPointsMinDistInterval" 
                                placeholder=""
                                onChange={(e) => setDrivingPointsMinDistInterval(e.target.value)}
                                value={drivingPointsMinDistInterval}
                            />
                            <label htmlFor="drivingPointsMinDistInterval" className={drivingPointsMinDistInterval ? 'active' : ''}>Points MinDist Interval</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="drivingFrequencyThreshold"
                                name="drivingFrequencyThreshold" 
                                placeholder=""
                                onChange={(e) => setDrivingFrequencyThreshold(e.target.value)}
                                value={drivingFrequencyThreshold}
                            />
                            <label htmlFor="drivingFrequencyThreshold" className={drivingFrequencyThreshold ? 'active' : ''}>Frequency Threshold</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="drivingCountGraceMultiplier"
                                name="drivingCountGraceMultiplier" 
                                placeholder=""
                                onChange={(e) => setDrivingCountGraceMultiplier(e.target.value)}
                                value={drivingCountGraceMultiplier}
                            />
                            <label htmlFor="drivingCountGraceMultiplier" className={drivingCountGraceMultiplier ? 'active' : ''}>Count Grace Multiplier</label>
                        </div>
                    </td>
                    <td>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="transitInitialTimeoutSeconds"
                                name="transitInitialTimeoutSeconds"
                                placeholder=""
                                onChange={(e) => setTransitInitialTimeoutSeconds(e.target.value)}
                                value={transitInitialTimeoutSeconds}
                            />
                            <label htmlFor="transitInitialTimeoutSeconds" className={transitInitialTimeoutSeconds ? 'active' : ''}>Initial Timeout Seconds</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="transitPointsMinDistInterval"
                                name="transitPointsMinDistInterval" 
                                placeholder=""
                                onChange={(e) => setTransitPointsMinDistInterval(e.target.value)}
                                value={transitPointsMinDistInterval}
                            />
                            <label htmlFor="transitPointsMinDistInterval" className={transitPointsMinDistInterval ? 'active' : ''}>Points MinDist Interval</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="transitFrequencyThreshold"
                                name="transitFrequencyThreshold" 
                                placeholder=""
                                onChange={(e) => setTransitFrequencyThreshold(e.target.value)}
                                value={transitFrequencyThreshold}
                            />
                            <label htmlFor="transitFrequencyThreshold" className={transitFrequencyThreshold ? 'active' : ''}>Frequency Threshold</label>
                        </div>
                        <div className="input-field">
                            <input 
                                type="number"
                                id="transitCountGraceMultiplier"
                                name="transitCountGraceMultiplier" 
                                placeholder=""
                                onChange={(e) => setTransitCountGraceMultiplier(e.target.value)}
                                value={transitCountGraceMultiplier}
                            />
                            <label htmlFor="transitCountGraceMultiplier" className={transitCountGraceMultiplier ? 'active' : ''}>Count Grace Multiplier</label>
                        </div>
                    </td>
                </tr>
            </table>

              <a className='dropdown-trigger btn' href='#!' data-target='env-dropdown'>{envState || "Prod"}</a>
              <ul id='env-dropdown' className='dropdown-content' ref={dropdownRef}>
                  <li><a href="#!" onClick={handleProd}>Prod</a></li>
                  <li><a href="#!" onClick={handleDev}>Dev</a></li>
              </ul>

              <div className="input-field center-align">
                  <button type="submit" className="btn">Submit</button>
              </div>
            </form>
        </div>
    )
}

export default ConfigurationEdit;
