import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';

function UserUpdate() {
    const [userName, setUserName] = useState("");
    const [userType, setUserType] = useState("");
    const [unassignedSecondaryUsers, setUnassignedSecondaryUsers] = useState([]);
    const [selectedSecondaryUsers, setSelectedSecondaryUsers] = useState([]);
    const [message, setMessage] = useState("");
    let { id } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const fetchUser = useCallback(async () => {
      try {
          const token = sessionStorage.getItem("token");
          const requestOptions = {
              method: 'GET',
              headers: { 'Authorization': token, 'Content-Type': 'application/json' }
          };
          const response = await fetch(`/api/admin/user/${id}`, requestOptions);
          const data = await response.json();
          if (response.status === 200) {
            setUserName(data.user_name)
            setUserType(data.user_type);
            if (data.user_type === "primary") {
                setSelectedSecondaryUsers(data.assigned_secondary_users.map(user => user.id));
                fetchUnassignedSecondaryUsers(id);
            }
          } else if (response.status === 401) {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("currentAdminId");
              navigate("/login");
          }
      } catch (err) {
          setMessage("Error fetching user");
      }
    }, [id, navigate]);

    const fetchUnassignedSecondaryUsers = useCallback(async (primaryUserId) => {
      try {
          const token = sessionStorage.getItem("token");
          const requestOptions = {
              method: 'GET',
              headers: { 'Authorization': token, 'Content-Type': 'application/json' }
          };
          const response = await fetch(`/api/admin/unassigned_secondary_users/${primaryUserId}`, requestOptions);
          const data = await response.json();
          if (response.status === 200) {
              setUnassignedSecondaryUsers(data.users);
          } else if (response.status === 401) {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("currentAdminId");
              navigate("/login");
          }
      } catch (err) {
          setMessage("Error fetching unassigned secondary users");
      }
    }, [navigate]);

    const handleSelectChange = (e) => {
      const selectedValues = [...e.target.options]
          .filter(option => option.selected)
          .map(option => Number(option.value));
      setSelectedSecondaryUsers(selectedValues);
    };

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (!userName.trim()) {
          setMessage("Username cannot be empty!");
          return;
        }

        try {
            const token = sessionStorage.getItem("token");

            if (token === null) {
              navigate("/login");
              return;
            }

            const requestOptions = {
              method: 'PUT',
              headers: { 'Authorization': token, 'Content-Type': 'application/json' },
              body: JSON.stringify({ user_name: userName, secondary_users: selectedSecondaryUsers })
            };
            let response = await fetch(`/api/admin/user/${id}`, requestOptions);
          if (response.status === 200) {
            navigate('/user_list', { replace: true });
          } else if (response.status === 401) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("currentAdminId");
            navigate("/login");
          } else {
            setMessage("Some error occured");
          }
        } catch (err) {
          setMessage("An unexpected error occurred");
        }
    };

    const mergeWithoutDuplicates = (arr1, arr2) => {
        const combinedArray = [...arr1, ...arr2];
        const uniqueArray = Array.from(new Set(combinedArray.map(user => user.id)))
                                .map(id => combinedArray.find(user => user.id === id));
        return uniqueArray;
    }
    
    const allUsers = mergeWithoutDuplicates(
      unassignedSecondaryUsers,
      selectedSecondaryUsers.map(id => ({ id, name: id.toString() }))
    );
    
    useEffect(() => {
      if (sessionStorage.getItem("token")) {
          fetchUser();
      }
    }, [fetchUser]);

    useEffect(() => {
      if (!isAuthenticated) {
          navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
      if (userType === "primary") {
        const elems = document.querySelectorAll('select');
        const instances = window.M.FormSelect.init(elems);
      }
  }, [unassignedSecondaryUsers, userType]);

    return (
      <div className="container">
          <h4 className="center-align">Update User</h4>
          {message && <p className="red-text">{message}</p>}
          <form onSubmit={handleSubmit} className="col s12">
              <div className="row">
                  <div className="input-field col s12">
                      <input 
                          id="username" 
                          type="text" 
                          name="username" 
                          placeholder="" 
                          onChange={(e) => setUserName(e.target.value)} 
                          value={userName}
                      />
                      <label htmlFor="username" className={userName ? 'active' : ''}>Username</label>
                  </div>

                  {userType === "primary" && (
                    <div className="input-field col s12">
                      <select multiple value={selectedSecondaryUsers} onChange={handleSelectChange}>
                          <option value="" disabled>Choose your option</option>
                          {allUsers.map(user => (
                              <option value={user.id} key={user.id}>{user.name}</option>
                          ))}
                      </select>
                        <label>Select secondary users to assign to {userName}</label>
                    </div>
                  )}
              </div>
              <div className="row">
                  <div className="col s12">
                      <button type="submit" className="btn waves-effect waves-light">Update</button>
                  </div>
              </div>
          </form>
      </div>
  );
}

export default UserUpdate;
