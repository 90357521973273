import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function AdminList() {
  const { isAuthenticated, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();
  
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentAdminId = parseInt(sessionStorage.getItem("currentAdminId"), 10);

  const fetchAdmins = useCallback(async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (token === null) {
        navigate("/login");
        return;
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/admins`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        setAdmins(data.admins);
        setIsLoading(false);
      } else if (response.status === 401) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("currentAdminId");
        navigate("/login");
      }
    } catch (err) {
      console.error("Error fetching admins:", err);
    }
  }, [navigate]);

  useEffect(() => {
    if (authLoading) return;
    
    if (isAuthenticated && sessionStorage.getItem("token")) {
      fetchAdmins();
    } else {
      navigate("/login");
    }
  }, [fetchAdmins, navigate, isAuthenticated]);

  const handleBan = async (adminId, event) => {
    event.preventDefault();

    const isConfirmed = window.confirm("Are you sure you want to ban this admin?");
    if (!isConfirmed) {
      return;
    }
  
    try {
      const token = sessionStorage.getItem("token");
      if (token === null) {
        navigate("/login");
        return;
      }
  
      const response = await fetch(`/api/admins/${adminId}/ban`, {
        method: 'PUT',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      });
      
      if (response.ok) {
        fetchAdmins();
      } else {
        console.error("Failed to ban admin");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h3 className="center-align">Admin List</h3>

      <div className="left-align" style={{ marginBottom: '20px' }}>
        <Link to="/admin/create" className="btn waves-effect waves-light">
          Create Admin
        </Link>
      </div>

      <ul className="collection">
      {admins.map(admin => (
          <li 
              key={admin.id} 
              className={`collection-item ${admin.id === currentAdminId ? 'highlighted-admin' : ''}`} 
              style={admin.status === 'banned' ? { backgroundColor: '#FFEBE8' } : {}}
          >
              <span className="title">{admin.login}</span>
              <p>
                  {admin.status === 'banned' ? 
                      <span style={{ color: 'black' }}>Banned</span> :
                      <>
                          <Link 
                              to={`/admin/${admin.id}/edit`} 
                              className="btn-small waves-effect waves-light blue lighten-1" 
                              style={{ marginRight: '10px' }}
                          >
                              Edit
                          </Link>
                          {admin.id !== currentAdminId && 
                              <a href="#!" className="btn-small red lighten-2" onClick={(event) => handleBan(admin.id, event)}>
                                  Ban
                              </a>
                          }
                      </>
                  }
              </p>
          </li>
      ))}
      </ul>
    </div>
  );
}

export default AdminList;
