import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';
import M from 'materialize-css';

function UserCreate() {
    const [userName, setUserName] = useState("");
    const [userType, setUserType] = useState("");
    const [message, setMessage] = useState("");
    const { isAuthenticated } = useAuth();
    const [activationCode, setActivationCode] = useState("");
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const handlePrimary = () => {
        setUserType("primary");
    };

    const handleSecondary = () => {
        setUserType("secondary");
    };

    let handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!userName.trim()) {
            setMessage("Username cannot be empty.");
            return;
        }
    
        try {
            const token = sessionStorage.getItem("token");
            if (token === null) {
                navigate("/login");
                return;
            }
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': token, 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_name: userName, user_type: userType })
            };
            let response = await fetch(`/api/admin/create_user`, requestOptions);
            const data = await response.json();
    
            if (response.status === 200) {
                setActivationCode(data.activation_code);
                setTimeout(() => {
                    const modalInstance = M.Modal.getInstance(modalRef.current);
                    if (modalInstance) {
                        modalInstance.open();
                    }
                }, 0);
            } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("currentAdminId");
                navigate("/login");
            } else {
                setMessage(data.message || "Some error occurred");
            }
        } catch (err) {
            setMessage("An unexpected error occurred");
        }
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (dropdownRef.current) {
            const initTimeout = setTimeout(() => {
                var elems = document.querySelectorAll('.dropdown-trigger');
                M.Dropdown.init(elems);
            }, 0);
    
            return () => {
                clearTimeout(initTimeout);
                if (dropdownRef.current) {
                    const instance = M.Dropdown.getInstance(dropdownRef.current);
                    if (instance) {
                        instance.destroy();
                    }
                }
            };
        }
    }, [dropdownRef]);

    useEffect(() => {
        if (modalRef.current) {
            M.Modal.init(modalRef.current, {
                onCloseEnd: () => {
                    navigate('/user_list', { replace: true });
                }
            });
        }
    
        return () => {
            if (modalRef.current) {
                const instance = M.Modal.getInstance(modalRef.current);
                if (instance) {
                    instance.destroy();
                }
            }
        };
    }, []);
    return (
        <div className="container">
            <h3 className="center-align">Create User</h3>
            {message && <p className="red-text">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <input
                        id="username"
                        type="text"
                        className="validate"
                        placeholder=""
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <label htmlFor="username">Username</label>
                </div>
                <div>
                    <a className='dropdown-trigger btn' href='#!' data-target='env-dropdown'>{userType || "Chose user type"}</a>
                    <ul id='env-dropdown' className='dropdown-content' ref={dropdownRef}>
                        <li><a href="#!" onClick={handlePrimary}>Primary</a></li>
                        <li><a href="#!" onClick={handleSecondary}>Secondary</a></li>
                    </ul>
                </div>
                <div className="input-field center-align">
                    <button className="btn waves-effect waves-light" type="submit">
                        Submit
                    </button>
                </div>
            </form>
            <div id="activationCodeModal" className="modal" ref={modalRef}>
            <div className="modal-content">
                <h4>User created successfully!</h4>
                <p>Activation Code: {activationCode}</p>
                </div>
                <div className="modal-footer">
                <button className="modal-close waves-effect waves-green btn-flat">Close</button>
                </div>
            </div>
        </div>
    )
}

export default UserCreate;
