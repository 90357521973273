import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';

function AdminResetPassword(props) {
    const id = props.id;
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password.trim()) {
          setMessage("Password cannot be empty!");
          return;
        }

        try {
            const token = sessionStorage.getItem("token");

            if (token === null) {
              navigate("/login");
              return;
            }

            const requestOptions = {
                method: 'PUT',
                headers: { 'Authorization': token, 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: password })
            };
            const response = await fetch(`/api/admin/${id}/reset_password`, requestOptions);
            if (response.status === 200) {
                navigate('/', { replace: true });
              } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("currentAdminId");
                navigate("/login");
              } else {
                setMessage("Some error occured");
            }
        } catch (err) {
          setMessage("An unexpected error occurred");
        }
    };

    useEffect(() => {
      if (!isAuthenticated) {
          navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    return (
        <div>
            <h4 className="center-align">Reset Password</h4>
            {message && <p className="red-text">{message}</p>}
            <form onSubmit={handleSubmit} className="col s12">
                <div className="row">
                    <div className="input-field col s12">
                        <input 
                            id="password" 
                            type="password" 
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)} 
                            value={password}
                        />
                        <label htmlFor="password" className={password ? 'active' : ''}>Password</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <button type="submit" className="btn waves-effect waves-light">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AdminResetPassword;