import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/AuthContext';

function AdminCreate() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    let handleSubmit = async (e) => {
        e.preventDefault();
   
        if (!login.trim() || !password.trim()) {
            setMessage("Username and password cannot be empty.");
            return;
        }

        try {
            const token = sessionStorage.getItem("token");
            if (token === null) {
                navigate("/login");
                return;
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': token, 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: login, password: password })
            };
            let response = await fetch(`/api/admin/create`, requestOptions);
            const data = await response.json();
            
            if (response.status === 200) {
                navigate('/', { replace: true });
            } else if (response.status === 401) {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("currentAdminId");
                navigate("/login");
            } else {
                setMessage(data.message || "Some error occurred");
            }
        } catch (err) {
            setMessage("An unexpected error occurred");
        }
    };
    
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="container">
            <h3 className="center-align">Create Admin</h3>
            {message && <p className="red-text">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-field">
                    <input 
                        id="username" 
                        type="text" 
                        className="validate"
                        placeholder="" 
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <label htmlFor="username">Username</label>
                </div>
                <div className="input-field">
                    <input 
                        id="password" 
                        type="password" 
                        className="validate"
                        placeholder="" 
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password">Password</label>
                </div>
                <button className="btn waves-effect waves-light" type="submit">
                    Submit
                </button>
            </form>
        </div>
    )
}

export default AdminCreate;
