import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import AdminResetPassword from './AdminResetPassword';
import { useAuth } from '../contexts/AuthContext';

function AdminUpdate() {
    const [adminLogin, setAdminLogin] = useState("");
    const [message, setMessage] = useState("");
    let { id } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const fetchAdmin = useCallback(async () => {
      try {
          const token = sessionStorage.getItem("token");
          const requestOptions = {
              method: 'GET',
              headers: { 'Authorization': token, 'Content-Type': 'application/json' }
          };
          const response = await fetch(`/api/admin/${id}`, requestOptions);
          const data = await response.json();
          if (response.status === 200) {
              setAdminLogin(data.admin_login)
          } else if (response.status === 401) {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("currentAdminId");
              navigate("/login");
          }
      } catch (err) {
          setMessage("Error fetching admins");
      }
    }, [id, navigate]);

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (!adminLogin.trim()) {
          setMessage("Username cannot be empty!");
          return;
        }

        try {
            const token = sessionStorage.getItem("token");

            if (token === null) {
              navigate("/login");
              return;
            }

            const requestOptions = {
                method: 'PUT',
                headers: { 'Authorization': token, 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: adminLogin })
            };
            let response = await fetch(`/api/admin/${id}`, requestOptions);
          if (response.status === 200) {
            navigate('/', { replace: true });
          } else if (response.status === 401) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("currentAdminId");
            navigate("/login");
          } else {
            setMessage("Some error occured");
          }
        } catch (err) {
          setMessage("An unexpected error occurred");
        }
    };
    
    useEffect(() => {
      if (sessionStorage.getItem("token")) {
          fetchAdmin();
      }
    }, [fetchAdmin]);

    useEffect(() => {
      if (!isAuthenticated) {
          navigate('/login');
      }
    }, [isAuthenticated, navigate]);

    return (
      <div className="container">
          <h4 className="center-align">Update Admin</h4>
          {message && <p className="red-text">{message}</p>}
          <form onSubmit={handleSubmit} className="col s12">
              <div className="row">
                  <div className="input-field col s12">
                      <input 
                          id="username" 
                          type="text" 
                          name="username" 
                          placeholder="" 
                          onChange={(e) => setAdminLogin(e.target.value)} 
                          value={adminLogin}
                      />
                      <label htmlFor="username" className={adminLogin ? 'active' : ''}>Username</label>
                  </div>
              </div>
              <div className="row">
                  <div className="col s12">
                      <button type="submit" className="btn waves-effect waves-light">Update</button>
                  </div>
              </div>
          </form>
          <AdminResetPassword id={id}/>
      </div>
  );
}

export default AdminUpdate;
