import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import AdminList from './components/AdminList';
import Login from './components/Login'
import AdminCreate from './components/AdminCreate';
import AdminUpdate from './components/AdminUpdate';
import ConfigurationEdit from './components/ConfigurationEdit';
import UserCreate from './components/UserCreate';
import UserList from './components/UserList';
import UserUpdate from './components/UserUpdate';
import './App.css';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<AdminList />} />
          <Route path="/admin_list" element={<AdminList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/create" element={<AdminCreate />}  />
          <Route path="/admin/:id/edit" element={<AdminUpdate />} />
          <Route path="/configuration/edit" element={<ConfigurationEdit />} />
	  <Route path="/admin/user_create" element={<UserCreate />} />
	  <Route path="/user_list" element={<UserList />} />
	  <Route path="/user/:id" element={<UserUpdate />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
