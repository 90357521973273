import { useAuth } from '../contexts/AuthContext';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Login() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { logIn } = useAuth();

    let handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!login || !password) {
        setMessage("Username and password must be provided");
        return;
      }
    
      setLoading(true);
    
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: login, password: password })
        };

        let response = await fetch(`/api/login`, requestOptions);
        
        if (response.status === 200) {
          let resJson = await response.json();
          sessionStorage.setItem('token', resJson.token);
          sessionStorage.setItem('currentAdminId', resJson.adminId);
          logIn();
          navigate('/', { replace: true });
        } else if (response.status === 403) {
          let errorResponse = await response.json();
          setMessage(errorResponse.message || "Some error occurred");
        } else if (response.status === 401) {
          setMessage("Username or password incorrect");
        } else {
          setMessage("Some other error occurred");
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        setMessage("Unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    };    

    return (
      <div className="container">
        <h3 className="center-align">Login</h3>
        {message && <p className="red-text">{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              id="username"
              type="text"
              className="validate"
              onChange={(e) => setLogin(e.target.value)}
            />
            <label htmlFor="username">UserName</label>
          </div>
          <div className="input-field">
            <input
              id="password"
              type="password"
              className="validate"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password">Password</label>
          </div>
          <button type="submit" className="btn waves-effect waves-light" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
          </button>
        </form>
      </div>
    );
}

export default Login;
