import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import M from 'materialize-css';

function UserList() {
  const { isAuthenticated, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUserId = parseInt(sessionStorage.getItem("currentUserId"), 10);
  const [successMessage, setSuccessMessage] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchUsers = useCallback(async (page = 1) => {
    try {
      const token = sessionStorage.getItem("token");
      if (token === null) {
        navigate("/login");
        return;
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/admin/users?page=${page}`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const { users, meta } = data;
        setUsers(users);
        setTotalPages(meta.pages);
        setCurrentPage(meta.page);
        setIsLoading(false);
      } else if (response.status === 401) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("currentAdminId");
        navigate("/login");
      }
    } catch (err) {
      console.error("Error fetching users", err);
    }
  }, [navigate]);

  const handleUserDelete = useCallback(async (userId) => {
    const confirmation = window.confirm("Are you sure you want to delete this user?");
    if (!confirmation) return;
    try {
      const token = sessionStorage.getItem("token");
      if (token === null) {
        navigate("/login");
        return;
      }

      const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      };
      const response = await fetch(`/api/admin/user/${userId}`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        setSuccessMessage('User delete successfully!');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
        fetchUsers();
      } else if (response.status === 401) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("currentAdminId");
        navigate("/login");
      }
    } catch (err) {
      console.error("Error fetching users", err);
    }
  }, [navigate]);

  const handleReactivate = async (userId) => {
    const confirmation = window.confirm("Are you sure you want to reactivate this user?");
    if (!confirmation) return;
  
    try {
      const token = sessionStorage.getItem("token");
      const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': token, 'Content-Type': 'application/json' }
      };
  
      const response = await fetch(`/api/admin/reactivate_user/${userId}`, requestOptions);
  
      if (response.status === 200) {
        const data = await response.json();
        setActivationCode(data.activation_code);
        const elems = document.querySelectorAll('#reactivationModal');
        const instances = M.Modal.init(elems);
        instances[0].open();
  
        fetchUsers();
      } else {
        const data = await response.json();
        console.error("Error reactivating user", data.message);
      }
    } catch (err) {
      console.error("Error reactivating user", err);
    }
};

  useEffect(() => {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems);

    if (authLoading) return;

    if (isAuthenticated && sessionStorage.getItem("token")) {
      fetchUsers();
    } else {
      navigate("/login");
    }
  }, [fetchUsers, navigate, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const changePage = (newPage) => {
    fetchUsers(newPage);
    setCurrentPage(newPage);
  };

  return (
    <div className="container">
      <h3 className="center-align">User List</h3>

      <div className="left-align" style={{ marginBottom: '20px' }}>
        <Link to="/admin/user_create" className="btn waves-effect waves-light">
          Create User
        </Link>
      </div>

      {successMessage && <p className="green-text">{successMessage}</p>}

      <ul className="collection">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Activated</th>
              <th>User type</th>
              <th>Associated Users Count</th>
              <th>Actions</th>
            </tr>
          </thead>
          {users.map(user => (
            <tbody>
              <tr>
                <td>{user.name}</td>
                <td>{user.activated ? 'Yes' : 'No'}</td>
                <td>{user.user_type}</td>
                <td>{user.user_type === "secondary" ? "-" : (user.secondary_users_count || 0)}</td>
                <td>
                  <li
                    key={user.id}
                    className={`collection-item ${user.id === currentUserId ? 'highlighted-user' : ''}`}
                  >
                    <Link
                      to={`/user/${user.id}`}
                      className="btn-small waves-effect waves-light blue lighten-1"
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </Link>

                    <button
                      onClick={() => handleReactivate(user.id)}
                      className="btn-small waves-effect waves-light green lighten-1"
                      style={{ marginRight: '10px' }}
                    >
                      Reactivate
                    </button>

                    <button
                      onClick={() => handleUserDelete(user.id)}
                      className="btn-small waves-effect waves-light red lighten-1"
                      style={{ marginRight: '10px' }}
                    >
                      Delete
                    </button>
                  </li>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </ul>
      <div id="reactivationModal" className="modal">
        <div className="modal-content">
          <h4>User reactivated successfully!</h4>
          <p>Activation Code: {activationCode}</p>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves-green btn-flat">Close</button>
        </div>
      </div>
      {totalPages > 1 && ( 
        <ul className="pagination">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            {currentPage === 1 ? (
              <span>Prev</span>
            ) : (
              <a onClick={() => changePage(currentPage - 1)}>Prev</a>
            )}
          </li>
          {[...Array(totalPages)].map((_, index) => (
            <li className={currentPage === index + 1 ? 'active' : ''} key={index}>
              <a onClick={() => changePage(index + 1)}>{index + 1}</a>
            </li>
          ))}
          <li className={currentPage === totalPages ? 'disabled' : ''}>
            {currentPage === totalPages ? (
              <span>Next</span>
            ) : (
              <a onClick={() => changePage(currentPage + 1)}>Next</a>
            )}
          </li>
        </ul>
      )}
    </div>
  );
}

export default UserList;
