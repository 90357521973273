import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const token = sessionStorage.getItem("token");
      if (token) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Authorization': token, 'Content-Type': 'application/json' }
        };
        const response = await fetch(`/api/verifyToken`, requestOptions);
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("currentAdminId");
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    verifyToken();
  }, []);

  const logIn = () => {
    setIsAuthenticated(true);
  };

  const logOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("currentAdminId");
    setIsAuthenticated(false);
  };

  const value = {
    isAuthenticated,
    isLoading,
    logIn,
    logOut
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
